<template>
  <div class="iosTip">
    <div class="header">
      <div class="backBtn" @click="$router.go(-1)"></div>
      <div class="title">IOS安装教程</div>
    </div>
    <div class="main">
      <img src="../../assets/images/mobile1/iosTutorial.webp" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  computed: {
    isPC() {
      return this.os.isPc;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.iosTip {
  background: #fff;
  width: 100%;
  height: 100%;
  .header {
    height: 44px;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    .backBtn {
      width: 24px;
      height: 24px;
      background-image: url("../../assets/images/mobile1/back.png");
      background-size: 100% 100%;
    }
    .title {
      flex: 2;
      text-align: center;
      margin-right: 24px;
    }
  }
  .main {
    width: 100%;
    height: calc(100vh - 44px);
    overflow-y: auto;
    img {
      width: 100%;
    }
  }
}
</style>
