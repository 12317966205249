<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="officialWebsite" @click="toOfficialWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="downloadBox">
      <div class="bottomBox">
        <div class="qrcodeBox">
          <img class="qrcode" :src="qrImg" alt="" />
        </div>
        <div class="btnBox">
          <div class="btn android"></div>
          <div class="btn ios"></div>
        </div>
      </div>
      <div class="installationTips"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/AWJD1");
    },
    toPotato() {
      window.open("https://dljpt6.org/awjd_app");
    },
    toOfficialWeb() {
      window.open("https://erosm.co");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    top: 54px;
    left: 81px;
    height: 102px;
    width: 302px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    top: 59px;
    right: 124.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .officialWebsite {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/officialWebsite.png")
        no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      margin-right: 30px;
    }
  }
  .figure {
    height: 934px;
    width: 818px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .logoText {
    height: 319px;
    width: 821px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 222px;
    top: 263px;
  }
  .downloadBox {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    position: absolute;
    left: 84px;
    bottom: 62px;
    .installationTips {
      width: 658px;
      height: 25px;
      background-image: url("../../../assets/images/pc/installationTips.png");
      background-size: 100% 100%;
    }
    .bottomBox {
      // margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 17px;
      display: flex;
      align-items: center;
      // justify-content: center;
      .qrcodeBox {
        width: 222px;
        height: 222px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 10px;
        .qrcode {
          width: 210px;
          height: 210px;
        }
      }
      .btnBox {
        margin-left: 18px;
        .btn {
          width: 398px;
          height: 102px;
        }
        .ios {
          background-image: url("../../../assets/images/pc/iosBtn.png");
          background-size: 100% 100%;
          margin-top: 11px;
        }
        .android {
          background-image: url("../../../assets/images/pc/androidBtn.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
