import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/land/index.vue";
import IosTip from "../views/iosTip/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: ""
    }
  },
  {
    path: "/iosTip",
    name: "IosTip",
    component: IosTip,
    meta: {
      title: ""
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
